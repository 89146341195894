<template>
  <main class="color-primary h-100 page_bg_grey blank-aside-js pb-20"
    :class="(isOpenAside == 'true') ? 'blank-aside' : ''">
    <p class="main-title weight-700">{{ $t("Sales and payments report") }}</p>
    <div class="filter-part weight-700">
      <h5 class="weight-700 mb-5">{{ $t("Time filtering") }}</h5>
      <date-range-picker ref="picker" :locale-data="dateRangeLocal" :timePicker="true" :timePicker24Hour="true"
        :showWeekNumbers="false" :showDropdowns="true" :autoApply="false" v-model="filter.dateRange"
        :linkedCalendars="true" class="cus-daterange-picker">
        <template v-slot:input="picker" style="min-width: 350px;">
          {{ picker.startDate | formatDateTime }} - {{ picker.endDate | formatDateTime }}
        </template>
        <template #ranges="ranges">
          <div class="ranges">
            <ul>
              <li v-for="(range, name) in ranges.ranges" :key="name" @click="ranges.clickRange(range)"
                v-if="name != 'This year'">
                <b>{{ $t(name) }}</b>
              </li>
            </ul>
          </div>
        </template>
      </date-range-picker>
    </div>
    <button class="primary-btn search_btn" @click="filterReport(1)">{{ $t("Search") }}</button>

    <div class="table-content-part">
      <div class="d-flex justify-content-between flex-wrap-reverse">
        <div class="search-bar">
          <p class="search-label">{{ $t("Search") }}</p>
          <v-text-field v-model="search" single-line hide-details class="search-form"
            @input="debounceSearch"></v-text-field>
        </div>
        <div class="total-value">
          <span class="pr-10">{{ $t("Current balance") }}: </span> {{ reportBalance }}
        </div>
      </div>
      <v-data-table :headers="headers" :search="search" :items="reportData.data"
        class="cus-table editable-table main-table striped-table" item-key="id" :loading="loading" :options.sync="options"
        :server-items-length="total" :footer-props="{
          'items-per-page-options': itemsPerPageOptions
        }">
        <template v-slot:[`item.business_name`]="{ item }">
          <div v-if="item.consumer_price < 0" class="color-red4">
            {{ item.business_name }}
          </div>
          <div v-else-if="item.action == 'payment'" class="green--text text--darken-4">
            {{ item.business_name }}
          </div>
          <div v-else>
            {{ item.business_name }}
          </div>
        </template>
        <template v-slot:[`item.business_id`]="{ item }">
          <div v-if="item.consumer_price < 0" class="color-red4">
            {{ item.business_id }}
          </div>
          <div v-else-if="item.action == 'payment'" class="green--text text--darken-4">
            {{ item.business_id }}
          </div>
          <div v-else>
            {{ item.business_id }}
          </div>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <div v-if="item.consumer_price < 0" class="color-red4">
            {{ item.action }}
          </div>
          <div v-else-if="item.action == 'payment'" class="green--text text--darken-4">
            {{ item.action }}
          </div>
          <div v-else>
            {{ item.action }}
          </div>
        </template>
        <template v-slot:[`item.supplier`]="{ item }">
          <div v-if="item.consumer_price < 0" class="color-red4">
            {{ item.supplier }}
          </div>
          <div v-else-if="item.action == 'payment'" class="green--text text--darken-4">
            {{ item.supplier }}
          </div>
          <div v-else>
            {{ item.supplier }}
          </div>
        </template>
        <template v-slot:[`item.product`]="{ item }">
          <div v-if="item.consumer_price < 0" class="color-red4">
            {{ item.product }}
          </div>
          <div v-else-if="item.action == 'payment'" class="green--text text--darken-4">
            {{ item.product }}
          </div>
          <div v-else>
            {{ item.product }}
          </div>
        </template>
        <template v-slot:[`item.invoice_number`]="{ item }">
          <div v-if="item.consumer_price < 0" class="color-red4">
            {{ item.invoice_number }}
          </div>
          <div v-else-if="item.action == 'payment'" class="green--text text--darken-4">
            {{ item.invoice_number }}
          </div>
          <div v-else>
            {{ item.invoice_number }}
          </div>
        </template>
        <template v-slot:[`item.date`]="{ item }">
          <div v-if="item.consumer_price < 0" class="color-red4">
            {{ item.date }}
          </div>
          <div v-else-if="item.action == 'payment'" class="green--text text--darken-4">
            {{ item.date }}
          </div>
          <div v-else>
            {{ item.date }}
          </div>
        </template>
        <template v-slot:[`item.time`]="{ item }">
          <div v-if="item.consumer_price < 0" class="color-red4">
            {{ item.time }}
          </div>
          <div v-else-if="item.action == 'payment'" class="green--text text--darken-4">
            {{ item.time }}
          </div>
          <div v-else>
            {{ item.time }}
          </div>
        </template>
        <template v-slot:[`item.consumer_price`]="{ item }">
          <div v-if="item.consumer_price < 0" class="color-red4">
            {{ item.consumer_price | formatPrice }}
          </div>
          <div v-else-if="item.action == 'payment'" class="green--text text--darken-4">
            {{ item.consumer_price | formatPrice }}
          </div>
          <div v-else>
            {{ item.consumer_price | formatPrice }}
          </div>
        </template>
        <template v-slot:[`item.business_price`]="{ item }">
          <div v-if="item.consumer_price < 0" class="color-red4">
            {{ item.business_price }}
          </div>
          <div v-else-if="item.action == 'payment'" class="green--text text--darken-4">
            {{ item.business_price }}
          </div>
          <div v-else>
            {{ item.business_price }}
          </div>
        </template>
        <template v-slot:[`item.balance2`]="{ item }">
          <div v-if="item.consumer_price < 0" class="color-red4">
            {{ item.balance2 }}
          </div>
          <div v-else-if="item.action == 'payment'" class="green--text text--darken-4">
            {{ item.balance2 }}
          </div>
          <div v-else>
            {{ item.balance2 }}
          </div>
        </template>
        <template v-slot:[`item.Remarks`]="{ item }">
          <div v-if="item.consumer_price < 0" class="color-red4">
            {{ item.Remarks }}
          </div>
          <div v-else-if="item.action == 'payment'" class="green--text text--darken-4">
            {{ item.Remarks }}
          </div>
          <div v-else>
            {{ item.Remarks }}
          </div>
        </template>
      </v-data-table>

    </div>
    <div class="action-part d-flex align-items-center flex-wrap">
      <div class="d-flex align-items-center" @click="printTableData">
        <img src="/assets/img/print.png" alt="" class="print-img hover-pointer mx-7">
        <label for="">{{ $t("For printing") }}</label>
      </div>
      <download-excel :fetch="fetchData" :fields="export_fields">
        <div class="d-flex align-items-center">
          <img src="/assets/img/excel.png" alt="" class="excel-img hover-pointer">
          <label for="">{{ $t("Export to Excel") }}</label>
        </div>
      </download-excel>
    </div>
    <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
  </main>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "SalesPaymentReport",
  components: {
    Loading
  },
  computed: {
    ...mapState({
      loading: state => state.report.loading,
      reportData: state => state.report.salesPaymentReportData_receipts,
      reportBalance: state => state.report.salesPaymentReportBalance_recepipts,
      printData: state => state.report.salesPaymentPrintData_receipts,
      total: state => state.report.salesPaymentReport_length,
    }),
    currentLang: function () {
      let currentLang = localStorage.getItem("language");
      currentLang = currentLang ? currentLang.toLowerCase() : 'he';
      return currentLang;
    }
  },
  data() {
    return {
      search: "",
      headers: [
        {
          text: this.$t("Business name"),
          align: "center",
          sortable: true,
          value: "business_name"
        },
        {
          text: this.$t("Business ID"),
          align: "center",
          sortable: true,
          value: "business_id"
        },
        {
          text: this.$t("Action"),
          align: "center",
          sortable: true,
          value: "action"
        },
        {
          text: this.$t("Supplier ID"),
          align: "center",
          sortable: true,
          value: "supplier"
        },
        {
          text: this.$t("Product"),
          align: "center",
          sortable: true,
          value: "product"
        },
        {
          text: this.$t("Tax. Acceptance transaction"),
          align: "center",
          sortable: true,
          value: "invoice_number"
        },
        {
          text: this.$t("Date"),
          align: "center",
          sortable: true,
          value: "date"
        },
        {
          text: this.$t("Hour"),
          align: "center",
          sortable: true,
          value: "time"
        },
        {
          text: this.$t("Consumer price"),
          align: "center",
          sortable: true,
          value: "consumer_price"
        },
        {
          text: this.$t("Cost"),
          align: "center",
          sortable: true,
          value: "business_price"
        },
        {
          text: this.$t("Balance"),
          align: "center",
          sortable: true,
          value: "balance2"
        },
        {
          text: this.$t("Remarks"),
          align: "center",
          sortable: true,
          value: "note"
        }
      ],
      filter: {
        dateRange: {
          startDate: moment().startOf('month'),
          endDate: moment().endOf('day')
        },
      },

      expanded: [],
      itemsPerPageOptions: [10, 15, 25, 50, 100],
      options: {
        itemsPerPage: 10,
        page: 1
      },
      filtersInUse: {},
      export_fields: {
        [this.$t("Business name")]: "business_name",
        [this.$t("Action")]: "action",
        [this.$t("Supplier ID")]: "supplier",
        [this.$t("Product")]: "product",
        [this.$t("Tax. Acceptance transaction")]: "invoice_number",
        [this.$t("Date")]: "date",
        [this.$t("Hour")]: "time",
        [this.$t("Consumer price")]: "consumer_price",
        [this.$t("Cost")]: "business_price",
        [this.$t("Balance")]: "balance2",
        [this.$t("Remarks")]: "note"
      },
    };
  },
  methods: {
    ...mapActions("report", {
      filter_sales_payment_report: "filter_sales_payment_report",
      filter_sales_payment_report_print: "filter_sales_payment_report_print",
      export_sales_report: "export_sales_report"
    }),

    debounceSearch() {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.filterReport(1);
      }, 1000)
    },

    prepareFilters() {
      let endDate = moment().format("YYYY-MM-DD");
      if (this.filter.dateRange.endDate != "") {
        endDate = moment(this.filter.dateRange.endDate).format("YYYY-MM-DD");
      }
      const filters = {
        startDate: moment(this.filter.dateRange.startDate).format("YYYY-MM-DD"),
        startDateTime: moment(this.filter.dateRange.startDate).format('hh:mm'),
        endDate: endDate,
        endDateTime: moment(endDate).format('hh:mm'),
        sortBy: this.options.sortBy[0],
        sortDesc: this.options.sortDesc[0],
        search: this.search
      }

      return filters;
    },
    filterReport(page) {
      page = +page || +this.options.page;
      this.options.page = page;
      let itemsPerPage = +this.options.itemsPerPage;
      let skip = (page - 1) * itemsPerPage;
      if (page === 1) {
        this.filtersInUse = this.prepareFilters()
      }
      const filters = this.filtersInUse;
      this.filter_sales_payment_report({ limit: itemsPerPage, skip: skip, filters: filters });
    },

    /* print table */
    async printTableData() {
      const filters = this.filtersInUse;
      const totalRowsNumber = this.total;
      if (totalRowsNumber != 0) {
        this.filter_sales_payment_report_print({ filters: filters, total: totalRowsNumber }).then(
          result => {
            printJS({
              printable: result,
              properties: [
                { field: 'note', displayName: this.$t("Remarks") },
                { field: 'balance2', displayName: this.$t("Balance") },
                { field: 'business_price', displayName: this.$t("Cost") },
                { field: 'consumer_price', displayName: this.$t("Consumer price") },
                { field: 'time', displayName: this.$t("Hour") },
                { field: 'date', displayName: this.$t("Date") },
                { field: 'invoice_number', displayName: this.$t("Tax. Acceptance transaction") },
                { field: 'product', displayName: this.$t("Product") },
                { field: 'supplier', displayName: this.$t("Supplier ID") },
                { field: 'action', displayName: this.$t("Action") },
                { field: 'business_name', displayName: this.$t("Business name") }
              ],
              type: 'json',
              gridHeaderStyle: this.currentLang == 'en' ? 'direction: ltr; border: 1px solid #3971A5;' : 'direction: rtl; border: 1px solid #3971A5;',
              gridStyle: this.currentLang == 'en' ? 'direction: ltr; border: 1px solid #3971A5;' : 'direction: rtl; border: 1px solid #3971A5;'
            })
          },
          error => {
          }
        );
      }
    },

    /*  export report data */
    async fetchData() {
      const filters = this.filtersInUse;
      const totalRowsNumber = this.total;
      if (totalRowsNumber != 0) {
        let result = await this.filter_sales_payment_report_print({ filters: filters, total: totalRowsNumber })
        return result;
      }
    }
  },
  watch: {
    options: {
      handler() {
        this.filterReport();
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
.filter-part {
  border: 1px solid #707070;
  margin: 20px 50px 0 50px;
  padding: 10px 15px 30px;
}

.total-value {
  font-weight: 600;
  color: #707070;
  font-size: 22px;

  span {
    font-size: 18px;
    color: #002036;
  }
}

.main-table {

  .print-img {
    width: 40px;
  }
}

.search_btn {
  margin: 0 auto;
  margin-top: 20px;
}

.action-part {
  margin: 20px 70px;

  .print-img {
    width: 50px;
  }

  .excel-img {
    width: 60px;
    margin-left: 50px;
    margin-right: 15px;
  }

  label {
    font-weight: 700;
    margin: 0;
  }
}

.rtl-type {
  .excel-img {
    margin-right: 50px;
    margin-left: 15px;
  }
}

@media screen and (max-width: 540px) {
  .action-part .excel-img {
    margin-left: 15px;
  }

  .rtl-type {
    .excel-img {
      margin-right: 15px;
    }
  }
}

@media screen and (max-width: 480px) {
  .filter-part {
    margin: 20px 20px 0;

  }

  .search_btn {
    max-width: 80%;
  }
}
</style>